
import { defineComponent, onMounted, ref, PropType, watchEffect, inject, toRaw, toRef } from 'vue'
import axios from '@/http'
import { getFieldFromPath } from '../getFieldFromPath'
interface ISelectOption {
	value: string
	label: string
}
interface IForm {
	title: string
	button: string
	url: string
	items: string
	first: string
	order: string
	gender: number
	category?: string
	subcategory?: string
	type?: string
	season?: string
	price?: string
	brand?: string
	size?: string
	material?: string
	color?: string
	country?: string
	totalPieces?: string
	totalWeight?: string
}

const orderItems = [
	{
		value: {
			param: 'created',
			type: 'ASC'
		},
		label: 'created ASC'
	},
	{
		value: {
			param: 'created',
			type: 'DESC'
		},
		label: 'created DESC'
	},
	{
		value: {
			param: 'price',
			type: 'ASC'
		},
		label: 'price ASC'
	},
	{
		value: {
			param: 'price',
			type: 'DESC'
		},
		label: 'price DESC'
	},
	{
		value: {
			param: 'views',
			type: 'ASC'
		},
		label: 'views ASC'
	},
	{
		value: {
			param: 'views',
			type: 'DESC'
		},
		label: 'views DESC'
	}
]

const rangeFieldList = ['price', 'totalPieces', 'totalWeight']

export default defineComponent({
	props: {
		screen: {
			type: Object
		},
		canUpdate: {
			type: Boolean
		},
		canRemove: {
			type: Boolean
		},
		canSave: {
			type: Boolean
		},
		isNewHomeScreen: {
			type: Boolean
		},
		selectedPlatforms: {
			type: Array as PropType<Array<string>>
		},
		selectedUserTypes: {
			type: Array as PropType<Array<string>>
		}
	},

	setup(props, { emit }) {
		const genders = toRaw([
			{
				name: 'women',
				key: 0,
				label: 'Женщины'
			},
			{
				name: 'men',
				key: 1,
				label: 'Мужчины'
			},
			{
				name: 'girls',
				key: 2,
				label: 'Девочки'
			},
			{
				name: 'boys',
				key: 3,
				label: 'Мальчики'
			},
			{
				name: 'bulk',
				key: 4,
				label: 'Лоты'
			}
		])

		const screenData = props?.screen
		const isNewHomeScreen = props?.isNewHomeScreen
		const selectedPlatforms = toRef(props, 'selectedPlatforms')
		const selectedUserTypes = toRef(props, 'selectedUserTypes')
		const isFetchingFieldFromUrl = ref<boolean>(false)
		const form = ref<IForm>({
			title: '',
			button: '',
			url: '',
			items: '',
			first: '6',
			order: 'created ASC',
			gender: 0,
			category: '',
			subcategory: '',
			type: '',
			season: '',
			price: '',
			brand: '',
			size: '',
			material: '',
			color: '',
			country: '',
			totalPieces: '',
			totalWeight: ''
		})
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const removeNewHomeScreen = inject<((id: string) => void) | undefined>('removeNewHomeScreen', undefined)
		const platforms = inject<Array<ISelectOption> | undefined>('platforms', undefined)
		const userTypes = inject<Array<ISelectOption> | undefined>('userTypes', undefined)

		watchEffect(() => {
			const canUpdate = props?.canUpdate
			if (canUpdate) {
				update()
			}
		})
		watchEffect(() => {
			const canRemove = props?.canRemove
			if (canRemove) {
				remove()
			}
		})
		watchEffect(() => {
			const { title, button, url } = form.value
			if (title && button && url) {
				emit('update:canSave', true)
			} else {
				emit('update:canSave', false)
			}
		})

		async function update() {
			try {
				if (!screenData || !platforms || !userTypes || !selectedPlatforms.value || !selectedUserTypes.value) return
				const { title, button, url } = form.value
				if (!title || !button || !url) {
					alert('Заполните обязательные поля')
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = {
					title,
					button,
					url,
					query: {}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedScreen: { [key: string]: any } = {
					type: screenData.type
				}
				if (!isNewHomeScreen) {
					updatedScreen.id = screenData?.id
				}
				const simpleFieldList = ['first', 'gender', 'category']
				const arrayFieldList = ['subcategory', 'season', 'brand', 'size', 'material', 'color', 'country', 'type']
				const items = form.value.items
				if (items) {
					data.query = {
						...data.query,
						items: items
							.split('\n')
							.map(i => i.trim())
							.filter(i => Boolean(i))
					}
				}
				for (const field of simpleFieldList) {
					const item = form.value?.[field as keyof IForm]
					if (item || item === 0) {
						data.query = {
							...data.query,
							[field]: Number(item)
						}
					}
				}
				for (const field of rangeFieldList) {
					const item = form.value?.[field as keyof IForm]
					if (item) {
						const arr = String(item).split(',')
						const range = {
							min: Number(arr[0].trim()),
							max: Number(arr[1].trim())
						}
						data.query = {
							...data.query,
							[field]: range
						}
					}
				}
				for (const field of arrayFieldList) {
					const item = form.value?.[field as keyof IForm]
					if (item) {
						data.query = {
							...data.query,
							[field]: String(item)
								.split(',')
								.map(i => Number(i.trim()))
						}
					}
				}
				const order = orderItems.find(o => o.label === form.value.order)
				if (order) {
					data.query.order = [order.value]
				}
				updatedScreen.data = JSON.stringify(data)
				for (const platform of platforms) {
					const idx = selectedPlatforms.value.findIndex(p => p === platform.value)
					updatedScreen[platform.value] = idx !== -1
				}
				for (const userType of userTypes) {
					const idx = selectedUserTypes.value.findIndex(p => p === userType.value)
					updatedScreen[userType.value] = idx !== -1
				}
				if (isNewHomeScreen) {
					await axios.post('/internal/create-home-screen', updatedScreen)
					remove()
				} else {
					await axios.post('/internal/update-home-screen', updatedScreen)
				}

				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canUpdate', false)
			}
		}
		async function remove() {
			try {
				if (isNewHomeScreen) {
					removeNewHomeScreen?.(screenData?.id)
					return
				}
				await axios.delete('/internal/remove-home-screen/' + screenData?.id)
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canRemove', false)
			}
		}

		function resetUrlField() {
			form.value = {
				...form.value,
				gender: 0,
				category: '',
				subcategory: '',
				type: '',
				season: '',
				price: '',
				brand: '',
				size: '',
				material: '',
				color: '',
				country: '',
				totalPieces: '',
				totalWeight: ''
			}
		}

		async function getFieldFromUrl() {
			try {
				isFetchingFieldFromUrl.value = true
				if (!screenData) return
				const url = form.value.url
				const idx = url.indexOf('catalog')
				if (idx === -1) return
				const shortUrl = url.slice(idx + 8)
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const pathFields: any = getFieldFromPath(shortUrl)
				if (!pathFields) return
				resetUrlField()
				const gender = pathFields.gender
				if (gender || gender === 0) {
					form.value.gender = gender
				}

				for (const field of rangeFieldList) {
					const item = pathFields[field]
					if (item?.min && item?.max) {
						form.value = {
							...form.value,
							[field]: `${item.min}, ${item.max}`
						}
						continue
					}
				}
				await getArrayFieldFromUrl(pathFields)
				await getTypeFromUrl(pathFields.type)
			} catch (e) {
				alert('ошибка парсинга' + JSON.stringify(e))
			} finally {
				isFetchingFieldFromUrl.value = false
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async function getArrayFieldFromUrl(pathFields: any) {
			const fields = ['brand', 'category', 'color', 'country', 'material', 'season', 'size']
			await Promise.all(
				fields.map(async field => {
					const item = pathFields[field]
					if (!item?.length) return
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const reqBody: any = {
						keys: item,
						tableName: field
					}
					const gender = pathFields.gender
					if (field === 'category' && (gender || gender === 0)) {
						reqBody.gender = gender
					}
					const res = await axios.post('/internal/get-id-by-table-field', reqBody)
					if (!res?.data) return
					if (field === 'category') {
						const [category, ...subcategory] = res.data
						if (category) {
							form.value.category = category
						}
						if (subcategory?.length) {
							form.value.subcategory = subcategory.join(', ')
						}
						reqBody.gender = gender
					} else {
						form.value = {
							...form.value,
							[field]: res.data.join(', ')
						}
					}
				})
			)
		}

		async function getTypeFromUrl(type: string[]) {
			if (!type?.length) return
			const reqBody: {
				keys: Array<string>
				categoryIds: number[]
			} = {
				keys: type,
				categoryIds: []
			}
			const formCategory = form.value.category
			const formSubcategory = form.value.subcategory
			if (formCategory && !isNaN(Number(formCategory))) {
				reqBody.categoryIds.push(+formCategory)
			}
			if (formSubcategory) {
				const subcategoryIds = formSubcategory.split(', ')
				for (const id of subcategoryIds) {
					if (id && !isNaN(Number(id))) {
						reqBody.categoryIds.push(+id)
					}
				}
			}
			const res = await axios.post('/internal/get-type-by-category-and-key', reqBody)
			if (res?.data) {
				form.value.type = res.data.join(', ')
			}
		}

		function validateDecimal(modelName: string) {
			return (val: string) => {
				if (!val.match(/^[1-9][0-9]*$/)) {
					form.value = {
						...form.value,
						[modelName]: val.slice(0, -1)
					}
				}
			}
		}
		function validateRange(modelName: string) {
			return (val: string) => {
				if (!val.match(/^(?:0*[1-9]\d*\s*(?:,|$)\s*)+$/gm)) {
					form.value = {
						...form.value,
						[modelName]: val.slice(0, -1)
					}
				}
			}
		}
		onMounted(() => {
			if (!isNewHomeScreen) {
				const { title, button, url, query } = screenData?.data
				const queryItems = query?.items
				const queryOrder = query?.order?.[0]
				const order = orderItems.find(o => o.value.param === queryOrder?.param && o.value.type === queryOrder?.type)
				form.value = {
					...form.value,
					title,
					button,
					url
				}
				if (order) {
					form.value.order = order.label
				}
				if (Array.isArray(queryItems)) {
					form.value.items = queryItems.map(i => i.trim()).join('\r\n')
				}
				const simpleFieldList = ['first', 'gender', 'category']
				const arrayFieldList = ['subcategory', 'season', 'brand', 'size', 'material', 'color', 'country']
				for (const field of simpleFieldList) {
					const item = query[field]
					if (item || item === 0) {
						form.value = {
							...form.value,
							[field]: item
						}
					}
				}
				for (const field of rangeFieldList) {
					const item = query[field]
					if (item?.min && item?.max) {
						form.value = {
							...form.value,
							[field]: `${item.min}, ${item.max}`
						}
					}
				}
				for (const field of arrayFieldList) {
					const item = query[field]
					if (item?.length) {
						form.value = {
							...form.value,
							[field]: item.join(', ')
						}
					}
				}
			}
		})
		return {
			screenData,
			form,
			orderItems,
			genders,
			isFetchingFieldFromUrl,

			validateDecimal,
			validateRange,
			getFieldFromUrl
		}
	}
})
