
import { defineComponent, PropType, onMounted, ref, inject, computed, toRef } from 'vue'
import axios from '@/http'
import { IHomeScreen } from '@/interfaces'
import type10 from './types/type10.vue'
import type20 from './types/type20.vue'
import type30 from './types/type30.vue'
import type40 from './types/type40.vue'
import type50 from './types/type50.vue'
import { ElMessageBox } from 'element-plus'
interface ISelectOption {
	value: string
	label: string
}
export default defineComponent({
	components: {
		type10,
		type20,
		type30,
		type40,
		type50
	},
	props: {
		screen: {
			type: Object
		},
		filteredHomeScreens: {
			type: Array as PropType<Array<IHomeScreen>>
		},
		platforms: {
			type: Array as PropType<Array<ISelectOption>>
		},
		userTypes: {
			type: Array as PropType<Array<ISelectOption>>
		}
	},

	setup(props) {
		const screenData = toRef(props, 'screen')
		if (screenData.value && typeof screenData.value.data === 'string') {
			screenData.value.data = JSON.parse(screenData.value.data)
		}
		const platformsData = props?.platforms
		const userTypesData = props?.userTypes
		const filteredHomeScreensData = toRef(props, 'filteredHomeScreens')
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const selectedPlatforms = ref<Array<string>>([])
		const selectedUserTypes = ref<Array<string>>([])
		const canUpdate = ref<boolean>(false)
		const canRemove = ref<boolean>(false)
		const canSave = ref<boolean>(false)
		onMounted(() => {
			if (!screenData.value || !platformsData || !userTypesData) return
			for (const platform of platformsData) {
				if (screenData.value[platform.value]) {
					selectedPlatforms.value.push(platform.value)
				}
			}
			for (const userType of userTypesData) {
				if (screenData.value[userType.value]) {
					selectedUserTypes.value.push(userType.value)
				}
			}
		})
		const isNewHomeScreen = screenData.value && screenData.value?.isNew
		function getComponentByType(type: number) {
			enum types {
				type10 = 10,
				type20 = 20,
				type30 = 30,
				type40 = 40,
				type50 = 50
			}
			return types[type]
		}
		const filteredHomeScreensByType = computed(
			() =>
				filteredHomeScreensData.value &&
				filteredHomeScreensData.value.filter(screen => 'id' in screen && getComponentByType(screen?.type))
		)
		async function decreaseOrder() {
			if (!filteredHomeScreensByType.value) return
			const idx = filteredHomeScreensByType.value.findIndex(screen => screen?.id === screenData.value?.id)
			if ([0, -1].includes(idx)) return
			await axios.post('/internal/change-order-home-screen-by-ids/', {
				ids: [screenData.value?.id, filteredHomeScreensByType.value[idx - 1]?.id]
			})
			await updateHomeScreens?.()
		}
		async function increaseOrder() {
			if (!filteredHomeScreensByType.value) return
			const idx = filteredHomeScreensByType.value.findIndex(screen => screen?.id === screenData.value?.id)
			const lastIdx = filteredHomeScreensByType.value.length - 1
			if ([lastIdx, -1].includes(idx)) return
			await axios.post('/internal/change-order-home-screen-by-ids/', {
				ids: [screenData.value?.id, filteredHomeScreensByType.value[idx + 1]?.id]
			})
			await updateHomeScreens?.()
		}

		function removeHomeScreen() {
			ElMessageBox.confirm('Вы точно хотите удалить блок?', {
				boxType: 'confirm',
				type: 'info',
				confirmButtonText: 'Удалить',
				cancelButtonText: 'Отменить'
			}).then(() => (canRemove.value = true))
		}
		return {
			screenData,
			platformsData,
			userTypesData,
			selectedPlatforms,
			selectedUserTypes,
			canUpdate,
			canRemove,
			isNewHomeScreen,
			canSave,

			getComponentByType,
			decreaseOrder,
			increaseOrder,
			removeHomeScreen
		}
	}
})
