const localizedGenders = [
	{ val: 'women', localize: { ru: { default: 'Для неё', headerMenu: 'Женское', pageBrand: 'Женские' } } },
	{ val: 'men', localize: { ru: { default: 'Для него', headerMenu: 'Мужское', pageBrand: 'Мужские' } } },
	{ val: 'girls', localize: { ru: { default: 'Для девочек', headerMenu: 'Для девочек', pageBrand: 'Для девочек' } } },
	{
		val: 'boys',
		localize: { ru: { default: 'Для мальчиков', headerMenu: 'Для мальчиков', pageBrand: 'Для мальчиков' } }
	},
	{
		val: 'bulk',
		localize: { ru: { default: 'Лоты', headerMenu: 'Лоты', pageBrand: 'Лоты' } }
	}
]

const modulesWithPrefix = [
	'material',
	'size',
	'color',
	'segment',
	'state',
	'season',
	'country',
	'price',
	'total-pieces',
	'total-weight',
	'item-price',
	'page'
]

function pathMatchWithPrefix(path: string, prefix: string): string | null {
	const re = new RegExp(`(${prefix}-.*?/)`)
	return (path.match(re)?.[0] || '').replace(/\//g, '') || null
}

function pathMatchWithPrefixRange(path: string, prefix: string): string | null {
	const re = new RegExp(`((${prefix}_(\\d+)-(\\d+))/)`)
	return (path.match(re)?.[0] || '').replace(/\//g, '') || null
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFieldFromPath(path: string): any {
	function getCategoriesWithType() {
		const gender = path.match(createRegexpByGenders())

		if (!gender || !(gender.index === 0 || gender.index)) return null

		const idxAfterCategory = path.search(
			/(\/type_.*?\/)|(\/season.*?\/)|(\/material.*?\/)|(\/size.*?\/)|(\/color.*?\/)|(\/price_.*?\/)|(\/page-.*?\/)|(\/total-pieces_.*?\/)|(\/total-weight_.*?\/)|(\/item-price_.*?\/)|(\/country.*?\/)/
		)

		return path.slice(gender.index + gender[0].length, idxAfterCategory) || null
	}

	function createRegexpByGenders(): RegExp {
		return new RegExp(localizedGenders.map(el => `(/${el.val}/)|(^${el.val}/)`).join('|'))
	}

	function getCategories() {
		const catWT = getCategoriesWithType()

		if (!catWT) return catWT

		const splitCat = catWT.split('/')

		if (splitCat.length < 3) return catWT
		else return splitCat.slice(0, 2).join('/')
	}

	function getTypesCategory() {
		const catWT = getCategoriesWithType()

		if (!catWT) return catWT

		const splitCat = catWT.split('/')

		if (splitCat.length < 3) return null
		else return splitCat[2]
	}

	function getBrands() {
		if (
			modulesWithPrefix.some(prefix => path.startsWith(prefix)) ||
			localizedGenders.map(el => el.val).includes(path.match(/^.*?\//)?.[0].replace('/', '') || '')
		) {
			return null
		}

		return (path.match(/^.*?\//)?.[0] || '').replace(/\//g, '') || null
	}

	function getSizes() {
		if (!getCategories()) return null
		return pathMatchWithPrefix(path, 'size')
	}

	function getPage() {
		if (!/(\/(page-\d+)\/)/.test(path)) return null

		const page = path.match(/(\/(page-\d+)\/)/)?.[0].replace(/\//g, '') || null

		if (!page || (Number(page.replace('page-', '')) || 0) < 2) return null

		return page
	}

	function getSeasons() {
		return pathMatchWithPrefix(path, 'season')
	}

	function getCountries() {
		return pathMatchWithPrefix(path, 'country')
	}

	function getGender() {
		return (path.match(createRegexpByGenders())?.[0] || '').replace(/\//g, '')
	}

	function getMaterials() {
		return pathMatchWithPrefix(path, 'material')
	}

	function getColors() {
		return pathMatchWithPrefix(path, 'color')
	}

	function getPrice() {
		return pathMatchWithPrefixRange(path, 'price')
	}

	function getTotalPieces() {
		return pathMatchWithPrefixRange(path, 'total-pieces')
	}

	function getTotalWeight() {
		return pathMatchWithPrefixRange(path, 'total-weight')
	}

	function getItemPrice() {
		return pathMatchWithPrefixRange(path, 'item-price')
	}

	// Составляю пути из строки
	const arrOrderPath = [
		{ key: 'brands', val: getBrands() },
		{ key: 'gender', val: getGender() },
		{ key: 'categories', val: getCategories() },
		{ key: 'types', val: getTypesCategory() },
		{ key: 'materials', val: getMaterials() },
		{ key: 'sizes', val: getSizes() },
		{ key: 'colors', val: getColors() },
		{ key: 'seasons', val: getSeasons() },
		{ key: 'countries', val: getCountries() },
		{ key: 'price', val: getPrice() },
		{ key: 'totalPieces', val: getTotalPieces() },
		{ key: 'totalWeight', val: getTotalWeight() },
		{ key: 'itemPrice', val: getItemPrice() },
		{ key: 'page', val: getPage() }
	]

	// Проверка последовательности
	if (
		path &&
		path !==
			arrOrderPath
				.map(el => el.val)
				.filter(Boolean)
				.join('/') +
				'/'
	)
		return null
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const orderPath = arrOrderPath.reduce((acc, { key, val }) => ({ ...acc, [key]: val }), {}) as any

	return {
		season: orderPath.seasons ? orderPath.seasons.replace('season-', '').split('_') : [],
		type: orderPath.types ? orderPath.types.split('_') : [],
		size: orderPath.sizes ? orderPath.sizes.replace('size-', '').split('_') : [],
		color: orderPath.colors ? orderPath.colors.replace('color-', '').split('_') : [],
		category: orderPath.categories ? orderPath.categories.split('/') : [],
		brand: orderPath.brands ? orderPath.brands.split('_') : [],
		material: orderPath.materials ? orderPath.materials.replace('material-', '').split('_') : [],
		gender: orderPath.gender ? localizedGenders.findIndex(el => el.val === orderPath.gender) : null,
		page: orderPath.page ? orderPath.page.replace('page-', '') : null,
		country: orderPath.countries ? orderPath.countries.replace('country-', '').split('_') : [],
		price: {
			min: orderPath.price ? orderPath.price.replace('price_', '').split('-')[0] : '',
			max: orderPath.price ? orderPath.price.replace('price_', '').split('-')[1] : ''
		},
		totalPieces: {
			min: orderPath.totalPieces ? orderPath.totalPieces.replace('total-pieces_', '').split('-')[0] : '',
			max: orderPath.totalPieces ? orderPath.totalPieces.replace('total-pieces_', '').split('-')[1] : ''
		},
		totalWeight: {
			min: orderPath.totalWeight ? orderPath.totalWeight.replace('total-weight_', '').split('-')[0] : '',
			max: orderPath.totalWeight ? orderPath.totalWeight.replace('total-weight_', '').split('-')[1] : ''
		},
		itemPrice: {
			min: orderPath.itemPrice ? orderPath.itemPrice.replace('item-price_', '').split('-')[0] : '',
			max: orderPath.itemPrice ? orderPath.itemPrice.replace('item-price_', '').split('-')[1] : ''
		}
	}
}
