
import { defineComponent, onMounted, ref, PropType, watchEffect, inject, toRef, computed } from 'vue'
import axios from '@/http'
import { isBoolean } from '@vueuse/core'
interface ISelectOption {
	value: string
	label: string
}
interface IForm {
	a: {
		title: string
		subtitle: string
		call: string
		promos: {
			first: {
				id: string
				label: string
				popular: boolean
			}
			second: {
				id: string
				label: string
				popular: boolean
			}
		}
		buttonActive: string
		buttonInactive: string
	}
	b: {
		title: string
		call: string
		expiring: string
		buttonActive: string
		buttonInactive: string
	}
}
interface IPromo {
	id: string
	name?: string
	isActive: boolean
	start?: Date
	finish?: Date
	actionMinsum?: number
	actionSale?: number
	actionMinus?: number
	type: number
	isWelcome: boolean
	created: Date
	modified: Date
	label?: string
}

export default defineComponent({
	props: {
		screen: {
			type: Object
		},
		canUpdate: {
			type: Boolean
		},
		canRemove: {
			type: Boolean
		},
		canSave: {
			type: Boolean
		},
		isNewHomeScreen: {
			type: Boolean
		},
		selectedPlatforms: {
			type: Array as PropType<Array<string>>
		},
		selectedUserTypes: {
			type: Array as PropType<Array<string>>
		}
	},

	setup(props, { emit }) {
		const screenData = props?.screen
		const isNewHomeScreen = props?.isNewHomeScreen
		const selectedPlatforms = toRef(props, 'selectedPlatforms')
		const selectedUserTypes = toRef(props, 'selectedUserTypes')
		const promos = ref<Array<IPromo>>([])
		const form = ref<IForm>({
			a: {
				title: '',
				subtitle: '',
				call: '',
				promos: {
					first: {
						id: '',
						label: '',
						popular: true
					},
					second: {
						id: '',
						label: '',
						popular: false
					}
				},
				buttonActive: '',
				buttonInactive: ''
			},
			b: {
				title: '',
				call: '',
				expiring: '604800000',
				buttonActive: '',
				buttonInactive: ''
			}
		})
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const removeNewHomeScreen = inject<((id: string) => void) | undefined>('removeNewHomeScreen', undefined)
		const platforms = inject<Array<ISelectOption> | undefined>('platforms', undefined)
		const userTypes = inject<Array<ISelectOption> | undefined>('userTypes', undefined)
		// если все поля заполнены или имеют булеву значения
		const maySave = computed(() => {
			const { a, b } = form.value
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return [a, b, a.promos.first, a.promos.second].every((item: any) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				Object.values(item).every((i: any) => isBoolean(i) || i)
			)
		})
		watchEffect(() => {
			const canUpdate = props?.canUpdate
			if (canUpdate) {
				update()
			}
		})
		watchEffect(() => {
			const canRemove = props?.canRemove
			if (canRemove) {
				remove()
			}
		})
		watchEffect(() => {
			emit('update:canSave', maySave.value)
		})
		watchEffect(() => {
			const firstId = form.value.a.promos.first.id
			const promo = promos.value.find(p => p.id === firstId)
			if (promo) {
				form.value.a.promos.first.label = promo.label || ''
			}
		})
		watchEffect(() => {
			const seconsId = form.value.a.promos.second.id
			const promo = promos.value.find(p => p.id === seconsId)
			if (promo) {
				form.value.a.promos.second.label = promo.label || ''
			}
		})

		async function update() {
			try {
				if (!screenData || !platforms || !userTypes || !selectedPlatforms.value || !selectedUserTypes.value) return
				if (!maySave.value) {
					alert('Заполните обязательные поля')
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = {
					a: {
						...form.value.a,
						promos: [form.value.a.promos.first, form.value.a.promos.second]
					},
					b: form.value.b
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedScreen: { [key: string]: any } = {
					type: screenData.type
				}
				if (!isNewHomeScreen) {
					updatedScreen.id = screenData?.id
				}
				updatedScreen.data = JSON.stringify(data)
				for (const platform of platforms) {
					const idx = selectedPlatforms.value.findIndex(p => p === platform.value)
					updatedScreen[platform.value] = idx !== -1
				}
				for (const userType of userTypes) {
					const idx = selectedUserTypes.value.findIndex(p => p === userType.value)
					updatedScreen[userType.value] = idx !== -1
				}
				if (isNewHomeScreen) {
					await axios.post('/internal/create-home-screen', updatedScreen)
					remove()
				} else {
					await axios.post('/internal/update-home-screen', updatedScreen)
				}

				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canUpdate', false)
			}
		}
		async function remove() {
			try {
				if (isNewHomeScreen) {
					removeNewHomeScreen?.(screenData?.id)
					return
				}
				await axios.delete('/internal/remove-home-screen/' + screenData?.id)
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canRemove', false)
			}
		}

		function validateDecimal(val: string) {
			if (!val.match(/^[1-9][0-9]*$/)) {
				form.value.b.expiring = val.slice(0, -1)
			}
		}

		async function fetchPromo() {
			try {
				const res = await axios.get('/internal/get-all-promos')
				if (res?.data) {
					promos.value = res.data
				}
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(async () => {
			await fetchPromo()
			if (isNewHomeScreen) return
			const { a, b } = screenData?.data
			form.value.a = {
				...a,
				promos: {
					first: a.promos[0],
					second: a.promos[1]
				}
			}
			form.value.b = b
		})

		function onChangePopular(promoOrder: 'first' | 'second') {
			form.value.a.promos.first.popular = false
			form.value.a.promos.second.popular = false
			form.value.a.promos[promoOrder].popular = true
		}
		return {
			screenData,
			form,
			promos,

			validateDecimal,
			onChangePopular
		}
	}
})
