
import { defineComponent, onMounted, ref, PropType, watchEffect, inject, toRef } from 'vue'
import axios from '@/http'
interface ISelectOption {
	value: string
	label: string
}
export default defineComponent({
	props: {
		screen: {
			type: Object
		},
		canUpdate: {
			type: Boolean
		},
		canRemove: {
			type: Boolean
		},
		canSave: {
			type: Boolean
		},
		isNewHomeScreen: {
			type: Boolean
		},
		selectedPlatforms: {
			type: Array as PropType<Array<string>>
		},
		selectedUserTypes: {
			type: Array as PropType<Array<string>>
		}
	},
	setup(props, { emit }) {
		const screenData = props?.screen
		const selectedPlatforms = toRef(props, 'selectedPlatforms')
		const selectedUserTypes = toRef(props, 'selectedUserTypes')
		const isNewHomeScreen = props?.isNewHomeScreen
		const title = ref<string>('')
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const removeNewHomeScreen = inject<((id: string) => void) | undefined>('removeNewHomeScreen', undefined)
		const platforms = inject<Array<ISelectOption> | undefined>('platforms', undefined)
		const userTypes = inject<Array<ISelectOption> | undefined>('userTypes', undefined)

		watchEffect(() => {
			const canUpdate = props?.canUpdate
			if (canUpdate) {
				update()
			}
		})
		watchEffect(() => {
			const canRemove = props?.canRemove
			if (canRemove) {
				remove()
			}
		})
		watchEffect(() => {
			if (title.value) {
				emit('update:canSave', true)
			} else {
				emit('update:canSave', false)
			}
		})
		async function update() {
			try {
				if (!platforms || !userTypes || !selectedPlatforms.value || !selectedUserTypes.value) return
				if (!title.value) {
					alert('Заполните обязательные поля')
				}
				const data = {
					...screenData?.data,
					title: title.value
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedScreen: { [key: string]: any } = {
					type: screenData?.type,
					data: JSON.stringify(data)
				}
				if (!isNewHomeScreen) {
					updatedScreen.id = screenData?.id
				}
				for (const platform of platforms) {
					const idx = selectedPlatforms.value.findIndex(p => p === platform.value)
					updatedScreen[platform.value] = idx !== -1
				}
				for (const userType of userTypes) {
					const idx = selectedUserTypes.value.findIndex(p => p === userType.value)
					updatedScreen[userType.value] = idx !== -1
				}

				if (isNewHomeScreen) {
					await axios.post('/internal/create-home-screen', updatedScreen)
					remove()
				} else {
					await axios.post('/internal/update-home-screen', updatedScreen)
				}
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canUpdate', false)
			}
		}
		async function remove() {
			try {
				if (isNewHomeScreen) {
					removeNewHomeScreen?.(screenData?.id)
					return
				}
				await axios.delete('/internal/remove-home-screen/' + screenData?.id)
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canRemove', false)
			}
		}
		onMounted(() => {
			if (screenData?.data) {
				title.value = screenData?.data.title
			}
		})
		return {
			screenData,
			title
		}
	}
})
