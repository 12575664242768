
import { computed, defineComponent, onMounted, provide, reactive, ref } from 'vue'
import axios from '@/http'
import TypeWrapper from './type-wrapper.vue'
import { IHomeScreen } from '@/interfaces'
const platforms = [
	{
		value: 'isActiveWeb',
		label: 'Сайт'
	},
	{
		value: 'isActiveIos',
		label: 'iOS'
	},
	{
		value: 'isActiveAndroid',
		label: 'Android'
	}
]
const userTypes = [
	{
		value: 'isAllowedGuests',
		label: 'Гости'
	},
	{
		value: 'isAllowedUsers',
		label: 'Зареганные'
	}
]

interface IFilterForm {
	platforms: Array<string>
	userTypes: Array<string>
}

const types = [10, 20, 30, 40, 50]

export default defineComponent({
	name: 'HomeEdit',
	components: {
		TypeWrapper
	},
	setup() {
		const filterForm: IFilterForm = reactive({
			platforms: [],
			userTypes: []
		})
		const homeScreens = ref<Array<IHomeScreen>>([])
		const newHomeScreens = ref<Array<IHomeScreen>>([])
		const showAddBtnItems = ref(false)
		provide('platforms', platforms)
		provide('userTypes', userTypes)
		const filteredHomeScreens = computed(() =>
			homeScreens.value.filter(screen => {
				if (filterForm.platforms?.length) {
					for (const platform of platforms) {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						if (filterForm.platforms.includes(platform.value) !== (screen as any)[platform.value]) return false
					}
				}
				if (filterForm.userTypes?.length) {
					for (const userType of userTypes) {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						if (filterForm.userTypes.includes(userType.value) !== (screen as any)[userType.value]) return false
					}
				}
				return true
			})
		)
		onMounted(async () => {
			await updateHomeScreens()
		})
		async function updateHomeScreens() {
			const res = await axios.get('/internal/get-all-home-screens')
			if (res) {
				homeScreens.value = res.data
			}
		}
		function removeNewHomeScreen(id: string) {
			const idx = newHomeScreens.value.findIndex(screen => screen.id === id)
			if (idx === -1) return
			newHomeScreens.value.splice(idx, 1)
		}
		async function onAddType(type: number) {
			showAddBtnItems.value = false
			const newHomeScreen: IHomeScreen = {
				isActiveWeb: false,
				isActiveAndroid: false,
				isActiveIos: false,
				isAllowedGuests: false,
				isAllowedUsers: false,
				type,
				created: new Date(),
				modified: new Date(),
				id: Date.now().toString(),
				isNew: true
			}
			newHomeScreens.value.push(newHomeScreen)
		}
		provide('updateHomeScreens', updateHomeScreens)
		provide('removeNewHomeScreen', removeNewHomeScreen)

		return {
			filterForm,
			platforms,
			userTypes,
			filteredHomeScreens,
			homeScreens,
			newHomeScreens,
			types,
			showAddBtnItems,

			onAddType
		}
	}
})
