<template>
	<HomeEdit />
</template>

<script>
import { defineComponent } from 'vue'
import HomeEdit from '@/components/home-edit/index.vue'
export default defineComponent({
	name: 'HomeEditPage',
	components: {
		HomeEdit
	}
})
</script>
