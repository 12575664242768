import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_form, {
      model: _ctx.form,
      "label-width": "180px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, { label: "Заголовок" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
              maxlength: "128"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Время слайда" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.time,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.time) = $event)),
              maxlength: "64",
              onInput: _cache[2] || (_cache[2] = val => _ctx.validateDecimal('time')(val))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Выборка товаров" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.items,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.items) = $event)),
              rows: 6,
              type: "textarea"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_form_item, { label: "Выводимое количество" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.form.first,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.first) = $event)),
              onInput: _cache[5] || (_cache[5] = val => _ctx.validateDecimal('first')(val))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}