
import { defineComponent, onMounted, ref, PropType, watchEffect, inject, toRef } from 'vue'
import axios from '@/http'
interface ISelectOption {
	value: string
	label: string
}
interface IForm {
	title: string
	time: string
	items: string
	first: string
}

export default defineComponent({
	props: {
		screen: {
			type: Object
		},
		canUpdate: {
			type: Boolean
		},
		canRemove: {
			type: Boolean
		},
		canSave: {
			type: Boolean
		},
		isNewHomeScreen: {
			type: Boolean
		},
		selectedPlatforms: {
			type: Array as PropType<Array<string>>
		},
		selectedUserTypes: {
			type: Array as PropType<Array<string>>
		}
	},

	setup(props, { emit }) {
		const screenData = props?.screen
		const isNewHomeScreen = props?.isNewHomeScreen
		const selectedPlatforms = toRef(props, 'selectedPlatforms')
		const selectedUserTypes = toRef(props, 'selectedUserTypes')
		const form = ref<IForm>({
			title: '',
			time: '5000',
			items: '',
			first: '6'
		})
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const removeNewHomeScreen = inject<((id: string) => void) | undefined>('removeNewHomeScreen', undefined)
		const platforms = inject<Array<ISelectOption> | undefined>('platforms', undefined)
		const userTypes = inject<Array<ISelectOption> | undefined>('userTypes', undefined)

		watchEffect(() => {
			const canUpdate = props?.canUpdate
			if (canUpdate) {
				update()
			}
		})
		watchEffect(() => {
			const canRemove = props?.canRemove
			if (canRemove) {
				remove()
			}
		})
		watchEffect(() => {
			const { title, time, items, first } = form.value
			if (title && time && items && first) {
				emit('update:canSave', true)
			} else {
				emit('update:canSave', false)
			}
		})

		async function update() {
			try {
				if (!screenData || !platforms || !userTypes || !selectedPlatforms.value || !selectedUserTypes.value) return
				const { title, time, items, first } = form.value
				if (!title || !time || !items || !first) {
					alert('Заполните обязательные поля')
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = {
					title,
					time,
					query: {
						first: +first
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedScreen: { [key: string]: any } = {
					type: screenData.type
				}
				if (!isNewHomeScreen) {
					updatedScreen.id = screenData?.id
				}
				if (items) {
					data.query = {
						...data.query,
						items: items
							.split('\n')
							.map(i => i.trim())
							.filter(i => Boolean(i))
					}
				}
				updatedScreen.data = JSON.stringify(data)
				for (const platform of platforms) {
					const idx = selectedPlatforms.value.findIndex(p => p === platform.value)
					updatedScreen[platform.value] = idx !== -1
				}
				for (const userType of userTypes) {
					const idx = selectedUserTypes.value.findIndex(p => p === userType.value)
					updatedScreen[userType.value] = idx !== -1
				}
				if (isNewHomeScreen) {
					await axios.post('/internal/create-home-screen', updatedScreen)
					remove()
				} else {
					await axios.post('/internal/update-home-screen', updatedScreen)
				}

				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canUpdate', false)
			}
		}
		async function remove() {
			try {
				if (isNewHomeScreen) {
					removeNewHomeScreen?.(screenData?.id)
					return
				}
				await axios.delete('/internal/remove-home-screen/' + screenData?.id)
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canRemove', false)
			}
		}

		function validateDecimal(modelName: string) {
			return (val: string) => {
				if (!val.match(/^[1-9][0-9]*$/)) {
					form.value = {
						...form.value,
						[modelName]: val.slice(0, -1)
					}
				}
			}
		}

		onMounted(() => {
			if (!isNewHomeScreen) {
				const { title, time, query } = screenData?.data
				const queryItems = query?.items
				const queryFirst = query?.first
				form.value = {
					...form.value,
					title,
					time,
					first: queryFirst || '6'
				}
				if (Array.isArray(queryItems)) {
					form.value.items = queryItems.map(i => i.trim()).join('\r\n')
				}
			}
		})
		return {
			screenData,
			form,

			validateDecimal
		}
	}
})
