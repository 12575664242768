
import { defineComponent, onMounted, ref, PropType, watchEffect, inject, toRef, toRaw } from 'vue'
import axios from '@/http'
interface ISelectOption {
	value: string
	label: string
}
interface IFormItem {
	gender: string
	title: string
	time: string
	items: string
	first: string
}

export default defineComponent({
	props: {
		screen: {
			type: Object
		},
		canUpdate: {
			type: Boolean
		},
		canRemove: {
			type: Boolean
		},
		canSave: {
			type: Boolean
		},
		isNewHomeScreen: {
			type: Boolean
		},
		selectedPlatforms: {
			type: Array as PropType<Array<string>>
		},
		selectedUserTypes: {
			type: Array as PropType<Array<string>>
		}
	},

	setup(props, { emit }) {
		const screenData = props?.screen
		const isNewHomeScreen = props?.isNewHomeScreen
		const selectedPlatforms = toRef(props, 'selectedPlatforms')
		const selectedUserTypes = toRef(props, 'selectedUserTypes')
		const form = ref<Array<IFormItem>>([])
		const updateHomeScreens = inject<(() => void) | undefined>('updateHomeScreens', undefined)
		const removeNewHomeScreen = inject<((id: string) => void) | undefined>('removeNewHomeScreen', undefined)
		const platforms = inject<Array<ISelectOption> | undefined>('platforms', undefined)
		const userTypes = inject<Array<ISelectOption> | undefined>('userTypes', undefined)
		const dublicatedGenderRow = ref<Array<number>>([])

		const genders = toRaw([
			{
				name: 'women',
				key: 0,
				label: 'Женщины'
			},
			{
				name: 'men',
				key: 1,
				label: 'Мужчины'
			},
			{
				name: 'girls',
				key: 2,
				label: 'Девочки'
			},
			{
				name: 'boys',
				key: 3,
				label: 'Мальчики'
			},
			{
				name: 'bulk',
				key: 4,
				label: 'Лоты'
			}
		])

		watchEffect(() => {
			const canUpdate = props?.canUpdate
			if (canUpdate) {
				update()
			}
		})
		watchEffect(() => {
			const canRemove = props?.canRemove
			if (canRemove) {
				remove()
			}
		})
		watchEffect(() => {
			if (!form.value?.length || dublicatedGenderRow.value.length) {
				emit('update:canSave', false)
				return
			}
			for (const formItem of form.value) {
				const { title, time, items, first, gender } = formItem
				if (!title || !time || !items || !first || !gender) {
					emit('update:canSave', false)
					return
				}
			}

			emit('update:canSave', true)
		})

		async function update() {
			try {
				if (dublicatedGenderRow.value.length) {
					alert('Заполните обязательные поля')
					throw 'Заполните обязательные поля'
				}
				if (!screenData || !platforms || !userTypes || !selectedPlatforms.value || !selectedUserTypes.value) return
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const data: any = {}
				form.value.forEach(formItem => {
					const { title, time, items, first, gender } = formItem
					if (!title || !time || !items || !first) {
						alert('Заполните обязательные поля')
						throw 'Заполните обязательные поля'
					}
					data[gender] = {
						title,
						time,
						query: {
							items: items
								.split('\n')
								.map(i => i.trim())
								.filter(i => Boolean(i)),
							first: +first
						}
					}
				})
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const updatedScreen: { [key: string]: any } = {
					type: screenData.type
				}
				if (!isNewHomeScreen) {
					updatedScreen.id = screenData?.id
				}
				updatedScreen.data = JSON.stringify(data)
				for (const platform of platforms) {
					const idx = selectedPlatforms.value.findIndex(p => p === platform.value)
					updatedScreen[platform.value] = idx !== -1
				}
				for (const userType of userTypes) {
					const idx = selectedUserTypes.value.findIndex(p => p === userType.value)
					updatedScreen[userType.value] = idx !== -1
				}
				if (isNewHomeScreen) {
					await axios.post('/internal/create-home-screen', updatedScreen)
					remove()
				} else {
					await axios.post('/internal/update-home-screen', updatedScreen)
				}

				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canUpdate', false)
			}
		}
		async function remove() {
			try {
				if (isNewHomeScreen) {
					removeNewHomeScreen?.(screenData?.id)
					return
				}
				await axios.delete('/internal/remove-home-screen/' + screenData?.id)
				await updateHomeScreens?.()
			} catch (e) {
				console.error('cant update', e)
			} finally {
				emit('update:canRemove', false)
			}
		}

		function validateDecimal(modelName: string, idx: number) {
			if (!form.value?.[idx]) return
			return (val: string) => {
				if (!val.match(/^[1-9][0-9]*$/)) {
					form.value[idx] = {
						...form.value[idx],
						[modelName]: val.slice(0, -1)
					}
				}
			}
		}
		function addSlider() {
			form.value?.push({
				gender: '',
				title: '',
				time: '5000',
				items: '',
				first: '6'
			})
		}

		onMounted(() => {
			if (isNewHomeScreen || !screenData?.data) return
			for (const gender in screenData?.data) {
				const { title, time, query } = screenData?.data[gender]
				const queryItems = query?.items
				const queryFirst = query?.first
				let items = ''
				if (Array.isArray(queryItems)) {
					items = queryItems.map(i => i.trim()).join('\r\n')
				}
				form.value.push({
					gender,
					title,
					time,
					first: queryFirst || '6',
					items
				})
			}
		})
		function onChangeGender(gender: string, idx: number) {
			const foundItems = form.value.filter(item => item.gender === gender)
			if (foundItems.length >= 2) {
				dublicatedGenderRow.value.push(idx)
			} else {
				dublicatedGenderRow.value = dublicatedGenderRow.value.filter(row => row !== idx)
			}
		}
		return {
			screenData,
			form,
			genders,
			dublicatedGenderRow,

			validateDecimal,
			addSlider,
			onChangeGender
		}
	}
})
